/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import "./has.js";
function n(n) {}
function t(n) {}
function o(n) {
  return () => n;
}
export { n as neverReached, t as neverReachedSilent, o as typeCast };